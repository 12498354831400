

.big--logo--wrap {
    width: 100%;
    display: grid;
    justify-content: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    transition: all 0.6s;
}





.big--logo--img--wrap {
    display: flex;
    flex-direction: column;
    justify-items: center;
    grid-column: 1;
    grid-row: 1;

}


.big--logo--img {
    width: 100%;
    position: relative;
    transition: 0.3;
}

#Z1 {
    z-index: 10;
    transition: 0.4s;
}

#Z2 {
    z-index: 11;
    transition: 0.6s;
    opacity: 1;
}

#Z3 {
    z-index: 12;
    transition: 0.6s;
    opacity: 1;
}

#Z1--trans {
    z-index: 10;
    transition: 0.6s;
    transform: scale(1.01);
}

#Z2--trans {
    z-index: 11;
    transform: scale(0.99);
    transition: 0.6s;
    opacity: 1;
}

#Z3--trans {
    z-index: 12;
    transform: scale(1.02);
    opacity: 1;
    transition: 0.6s;
}


#logo--light{
    width: 100%;
}




#logo--dark{
    filter: invert();
    transition: all 0.6s;
}

