.project-box {
    height: 100%;
    width: 100%;
    display: grid;
    justify-items: center;
    align-content: center;
    grid-template-rows: 85% 15%;
    box-shadow: 4px 4px 12px rgb(0, 0, 0, 0.15);
    background-color: rgb(248, 248, 248, 0.75);
    aspect-ratio: 1/1;
    z-index: 90;
    opacity: 1;
    transition: all 0.2s ;
    filter: saturate(1);
}

.project-box-hidden {

    height: 200px;
    width: 200px;
    display: grid;
    justify-items: center;
    align-content: center;
    grid-template-rows: 85% 15%;
    margin: 10px 10px;
    border-radius: 5px;
    box-shadow: 4px 4px 18px rgb(0, 0, 0, 0.05);
    background-color: rgb(248, 248, 248, 0.75);
    aspect-ratio: 1/1;
    z-index: 90;
    opacity: 1;
    transition: all 0.2s;
    transform: scale(0);
    position: fixed;
    font-size: 0px;
    
}



.box-link-hidden {
    transition: all 0.2s ;
}

.box-link-hidden{
    transition: all 0.2s;
    transform: scale(0);
    position: fixed;
    font-size: 0px;

}


.project-box:hover {
    transform: scale(1.03);
    background-color: rgb(248, 248, 248, 1);
    z-index: 90;
    transition: 0.2s;
    opacity: 1;
    cursor: pointer;
    transition: all 0.2s;
    filter: saturate(1);
    box-shadow: 6px 6px 16px rgb(0, 0, 0, 0.15);
    

}





#box--dark {
    background-color: rgba(12, 12, 12, 0.75);
    border-top: solid 0.5px rgb(41, 41, 41);

    color: rgb(255, 255, 255);
}








.trans-enter {
     transform: scale(0);
     opacity: 0;
}

.trans-enter-active {
    opacity: 1;
    transition: all 0.2s;
    transform:scale(1);
  
}

.trans-enter-done {
    opacity: 1;
    transform:scale(1);
  
}

.trans-exit {

    transform:scale(1);

}

.trans-exit-active{
    transition: all 0.1s;
    transform:scale(1);
    
  
}


.trans-exit-done {
    transform:scale(0); 
  
}


















.project--cover--wrap {
    width: 100%;
    height: 100%;
    transition: 0.6s;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgb(0, 0, 0);
    overflow: hidden;
}



.project--cover {
    width: 100%;
    transition: 6s;
    z-index: 1;
}

.project-box:hover .project--cover {

    transform: scale(1.075);
}



.project--name--wrap {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    color: rgb(255, 255, 255);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: right;
    padding-right: 5%;
    border: solid 1px black;
    border-radius: 0px 0px 4px 4px;
    transition: 0.2S;
    font-weight: 400;
    margin-bottom: 0;
}



.project--name {
    padding: 0;
    margin: 0;
}







@media screen and (max-width: 820px) {


    
    
    .project--name--wrap {

        font-size: 18px;
        
    }
    
}

@media screen and (max-width: 480px) {

    .project--name--wrap {

        font-size: 12px;
        
    }
    
    
}


