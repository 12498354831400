


/*NavBar Styles*/




.nav--bar--wrap{
    display: flex;
    justify-content:  center;
    width: 100%;
}


.nav--bar {
    position: fixed;
    height: 130px;
    width: 100%;

    top: 0px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    padding: 20px 10% 0px 10%;
    background-color: rgb(255, 255, 255, 0);
    z-index: 99;
    border-bottom: solid 1px rgb(225, 225, 225, 0);
    opacity: 1;
    transition: 0.3s;
    

}

.nav--bar:hover {
    background-color: rgb(255, 255, 255, 0.9);   
}

.nav--bar--hidden {
    width: 100%;
    padding: 20px 10% 0px 10%;
    position: fixed;
    height: 130px;
    top: 0px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: rgb(255, 255, 255);
    opacity: 1;
    transition: 0.3s;
    top: -130px;
}


#dark {
    filter: invert(100%);
} 


.nav--bar--elements--wrap {
width: 100%;
}


.ghost--bar {
    position: fixed;
    height: 18px;
    width: 100%;
    top: 118px;
    z-index: 100;

}






/*upperBar Styles*/


.upper--bar {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    padding-bottom: 12.5px;
    
}
.upper--bar--left--side {
    height: 100%;
}

.logo--wrap {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

#joscoyne--logo {
    height: 28px;
    padding-left: var(--sidePadding);
    transition: 0.1s;
}

#joscoyne--logo:hover {
    cursor: pointer;
    transform: scale(1.025);
}


.upper--bar--right--side {
    height: 100%;
    display: flex;;
    justify-content: right;
    align-items: center;
}

.toggle--switch--wrap {
    display: flex;
    align-items: center;
    padding-right: 7.5px;

}


#darkmode--text {
    align-self: center;   
}

.info--button--wrap{

    height: 60px;
    display: grid;
    justify-items: center;
    align-items: center;
}

#info--button {
    height: 30px;
    padding-right: var(--sidePadding)

}

.main--menu {
    display: flex;
    font-size: 0px;
    visibility: 0;
    transition: 1s;
    align-content: center;
}

.main--menu--visible {
    display: flex;
    font-size: 16px;
    margin-right: 52px;
    display:flex;
    transition: 0.1s;
    align-content: center;
}



.main--menu--link {
    display: flex;
    align-content:  center;
    width: 100%;
    transform: scale(0);
    padding:4%;
    transition: 0.1s;
}

.main--menu--link--visible {
    display: flex;
    align-content:  center;
    transform: scale();
    width: 100%;
    padding:4%;
    transition: 0.1s;
}

.main--menu--link:hover {
    align-self: center;
    width: 100%;
    padding:4%;
    font-weight: 700;
}



/*lower Bar Styles*/


.lower--bar {
    border-top: solid 2px rgb(0, 0, 0);
    padding-top: 4px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    
    

}

.lower--bar--left--side {
    display: flex;
    align-content: center;
    align-items: center;
}







.project--cat--menu--wrap {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: var(--sidePadding)

}





/*Main*/

.loading {
    width: 100vw;
    height: 5000px;
    display: flex;

}




.main {
    width: 100%;
    z-index: 90;
    transition: all 0.3s;
    margin-top: 140px;
}



.box--wrap {
    width: 100%;
    display: grid;
    gap:20px;
    grid-template-columns: repeat(4, 1fr);
    place-items: start;


}








@media screen and (max-width: 820px) {

    .nav--bar {
   
        
    
        padding: 20px 2% 0px 2%;
    
        
    
    }
    
    .nav--bar:hover {
        background-color: rgb(255, 255, 255, 0.9);   
    }
    
    .nav--bar--hidden {
        padding: 20px 2% 0px 2%;
      
    }

    .main {
        z-index: 90;
        transition: all 0.3s;
        margin-top: 140px;
        width: 100%;
        
    }

    .box--wrap {
        gap:10px;
        grid-template-columns: repeat(2, 1fr);

    
    }
    
    

   
  
  }

@media screen and (max-width: 480px) {

    .main {

        margin-top: 130px;
    }



    /*lower Bar Styles*/


.lower--bar {
    border-top: solid 2px rgb(0, 0, 0);
    padding-top: 4px;
    height: 50px;
    display: flex;
    justify-content: space-between;

    
    

}







.project--cat--menu--wrap {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: right;
    padding-right: var(--sidePadding);


}

    
   
  
  }