*{ 
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: 'Montserrat', sans-serif;
}


body {
  background-color: rgb(0, 0, 0);
}



/*Scrollbar Style*/


/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color:  rgba(0, 0, 0, 0.75);  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  transition: 0.3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.65);
}











code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




