.footer--wrap {
    margin-top: 4%;
    position: static;
    width: 100%;
    display: grid;
    justify-self: center;
    justify-items: center;
    border-top: solid 3px black;

}

.footer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin-bottom: 2.5%;
}


.social--media--wrap {
    grid-column: 2 / span 2;
    grid-row: 2;
    justify-self: right;
    display: flex;
}

.social--media--icon--wrap {
    display: flex;
    border-radius: 2px;
    margin: 2.5px;
}


.social--media--img {
    width: 45px;
    border-radius: 3px;
    margin: 2px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    transition: 0.1s;
    
}

.social--media--img:hover {
    opacity: 0.8;
}



.email--wrap{
    margin-top: 5%;
    margin-bottom: 5%;
    grid-column: 1 / span 2;
    transition: 0.1s;
    width: 100%;
}

.email--wrap:hover{
    cursor: pointer;
    transform: scale(1.01);


}



.clip--board{
    padding: 0;
}


.email {
    font-size: 48px;
    font-weight: 800;
}



.copyright--wrap {
    margin-top: 1%;
    grid-column: 3 / span 1;
    grid-row: 3;
    text-align: right;
}


#footer--dark {
    filter: invert();
}








@media screen and (max-width: 480px) {

    .footer--wrap {
        padding-left: 5%;
        padding-left: 5%;
        position: static;
        width: 100%;
        display: flex;
        justify-self: center;
        background-color: rgba(255, 255, 255, 0);
        padding: 0px;
        align-content: center;
        text-align: center;
        justify-content: center;
        
    
    }
    
    .footer {
        display: grid;
        grid-template-columns:  1fr;
        grid-template-rows: auto;
        text-align: center;
        justify-items: center;
        justify-content: center;
    }
    
    
    .social--media--wrap {
        grid-column:1;
        grid-row: 2;
        justify-self: right;
    }
    
    .social--media--icon--wrap {
        display: flex;
        border-radius: 5px;
        margin: 1.5px;
    }
    
    
    .social--media--img {
        width: 50px;
        border-radius: 2px;
        margin: 2px;
        box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
        transition: 0.1s;
    }
    
    .social--media--img:hover {
        opacity: 0.8;
    }
    
    
    
    .email--wrap{
        margin-top: 0%;
        margin-bottom: 5%;
        padding: 0;
        transition: 0.1s;
        align-self: center;
        text-align: center;
        grid-column: 1;
    }
    
    .email--wrap:hover{
        cursor: pointer;
        transform: scale(1.01);
    
    
    }
    
    
    
    
    .email {
        font-size: 33.5px;
        font-weight: 800;
    }
    
    .copyright--wrap {
        font-size: 14px;
        margin-top: 3%;
        grid-column: 1;
        grid-row: 3;
        text-align: right;
        justify-self: right;
    }
    
    
    #footer--dark {
        filter: invert();
    }
    


}

@media screen and (max-width: 320px)  {
    .social--media--img {
        width: 35px;
        border-radius: 2px;
        margin: 2px;
        box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
        transition: 0.1s;
    }

    .email {
        font-size: 22px;
        text-align: right;
    }
    
    .clip--board {
        font-size: 11px;
    }
}