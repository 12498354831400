
.toggle--switch--wrap {
  justify-items: center;
  margin-right: 10px;

}


.toggle--switch--icons {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-items: center;
  padding-bottom: 1.5px;
}

.toggle--switch--icon {
  width: 50%;
  align-self: center;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}





.toggle {

    position: relative;
    height: 20px;
    width: 37px;
    display: inline-block;
    border-radius: 45px;
    background-color: black;
}




.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1.5px;
    -webkit-transition: .4s;
    transition: .1s;
  }

 
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 3.5px;
    background-color: rgb(0, 0, 0);
    border: solid 2px white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 45px;
  }



  input:checked + .slider:before {
    -webkit-transform: translateX(15x);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    
  }




  



/* Hide default HTML checkbox */
