.gol-frame {
    width: 200px;
    grid-row: 2 / span 1;
    grid-column: 1/span 1;

}


ev2 {
    color: rgb(0, 0, 0);
    border-radius: 4px;
    border: solid 1px rgb(0, 0, 0, 0);
    font-size:24px;
    padding: 0px 4px 0px 4px;
    margin-left: 4px;
    font-weight: 800;
    transition: 0.3s;

}

ev2:hover {
    cursor: pointer;
    color:  rgb(0, 0, 0, 1);
    border: solid 1px rgb(0, 0, 0, 0.5);  
}

ev2:hover .tooltip-2 {
    opacity: 1;
    visibility: visible;
}






#jb-img-small {
    width: 95px;
    justify-self: center;
}




#link {
    text-decoration: underline;
    font-weight: 600;
    color: rgb(229, 138, 138);
    transition: 0.1s;
}

#link:hover {
    color: rgb(211, 85, 85);
}


ul {
    padding: 5%;
}

li {
    padding-top: 1%;
    font-weight: 300;
}




.tooltip-2 {

    line-height: 15px;
    width: 250px;
    padding: 0.75%;
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    display:inline-block;
    justify-items: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0.3s;
    margin: 10px;
    visibility: hidden;
    box-shadow: 4px 4px 12px rgb(0, 0, 0, 0.4);
    bottom: 25px;
}

.tooltip-julian-wrap {
    height: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    margin-bottom: 6%;

}
.tooltip-text {
margin: 0;
}
#tooltip-p {
    padding-bottom: 0%;
    margin-bottom: 0;
}






@media screen and (max-width: 480px) {

    .tooltip-2 {

        display: none;
    }



}