

.menu--button {
    padding: 2px 6px 3px 6px;
    transition: 0.2s;
    font-size: 16px;
    font-weight: 800;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    margin-left: 15px;
    border-radius: 3px;
    background-color: rgb(255, 55, 55, 0);
    cursor: pointer;
    border: solid 1px rgb(180, 180, 180, 0);

}

.menu--button:hover {
    border: solid 1px rgb(180, 180, 180);
    transition: 0.2s;
}

.menu--button--text {
    padding: 0;
    margin: 0;
}






input:checked + .menu--button {
    background-color: rgb(255, 55, 55, 1);
}




@media screen and (max-width: 480px) {

    .menu--button {
        padding: 3px 6px 3px 6px;
        transition: 0.3s;
        font-size: 14px;
        font-weight: 400;
        border: solid 1px rgb(0, 0, 0);
        color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        margin-left: 8px;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        cursor: pointer;
    
    }
    
    .menu--button:hover {
        background-color: rgb(230, 230, 230);
    }
    
    
    .menu--button--text {
    }
    
    
    input:checked + .menu--button {
        background-color: rgb(255, 55, 55, 1);
    }
    
    

}


@media screen and (max-width: 320px) {

    .menu--button {
        padding: 3px 6px 3px 6px;
        transition: 0.3s;
        font-size: 12px;
        font-weight: 400;
        border: solid 1px rgb(0, 0, 0);
        color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        margin-left: 6px;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        cursor: pointer;
    
    }
}