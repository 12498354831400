
.app {
  width: 80%;
  margin: auto;}


.get--back {
  width: 100%;
  height: 50px;
}




@media screen and (max-width: 820px) {

 
  
  .app {
    width: 95%;
  
}

@media screen and (max-width: 480px) {

  .app {
    width: 95%;
 
  }
}

}


