.gol-frame {
    width: 200px;
    grid-row: 2 / span 1;
    grid-column: 1/span 1;

}


jb {
    color: rgb(0, 0, 0);
    border-radius: 4px;
    border: solid 1px rgb(0, 0, 0, 0);
    font-size:32px;
    padding: 0px 4px 0px 4px;
    margin-left: 4px;
    font-weight: 800;
    transition: 0.3s;

}

jb:hover {
    cursor: pointer;
    color:  rgb(0, 0, 0, 1);
    border: solid 1px rgb(0, 0, 0, 0.5);  
}

jb:hover .tooltip {
    opacity: 1;
    visibility: visible;
}



ev{
  font-size: 32px;
  font-weight: 800;

}




#jb-img-small {
    width: 95px;
    justify-self: center;
    border-radius: 100%;
}




#link {
    text-decoration: underline;
    font-weight: 600;
    color: rgb(229, 138, 138);
    transition: 0.1s;
}

#link:hover {
    color: rgb(211, 85, 85);
}


ul {
    padding: 5%;
}

li {
    padding-top: 1%;
    font-weight: 300;
}




.tooltip {

    line-height: 15px;
    width: 250px;
    padding: 0.75%;
    background-color: black;
    color: white;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    display:inline-block;
    justify-items: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0.3s;
    margin: 10px;
    margin-top: 0;
    visibility: hidden;
    box-shadow: 4px 4px 12px rgb(0, 0, 0, 0.4);
}

.tooltip-julian-wrap {
    height: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    margin-bottom: 6%;

}
.tooltip-text {
margin: 0;
}
#tooltip-p {
    padding-bottom: 0%;
    margin-bottom: 0;
}



.about--wrap {
    
    padding-top: 5%;
    width: 100%;
    width: 100%;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    line-height: 36px;


  
}

.big--logo--wrap {
    grid-column: 1 / span 2;
}

.about--text--wrap{
    font-size: 22px;
    margin-top: 5%;
    margin-bottom: 5%;
    grid-column: 2 / span 2;
    transition: all 0.6s;
}

#about--dark {
    color: white;
    transition: all 0.6s;
}

.about--text {
    margin-bottom: 2%;
}


@media screen and (max-width: 480px) {

    jb{
        color: rgb(0, 0, 0);
        border-radius: 4px;
        border: solid 1px rgb(0, 0, 0, 0);
        font-size:22px;
        padding: 0px 2px 0px 2px;
        margin-left: 0px;
        font-weight: 800;
        transition: 0.3s;
        
        }
        

    .tooltip {
        display: none;
    }

    .about--wrap {
        grid-template-columns: 1fr;
      
    }

    .big--logo--wrap {
        grid-column: 1 
        
    }
    
    .about--text--wrap{

        font-size: 18px;
        line-height: 22px;
        margin-top: 5%;
        margin-bottom: 5%;
        
        grid-column: 1;
        transition: all 0.6s;
    }

    .about--text {
        margin-bottom: 5%;
    }

    ev{
        font-size: 22px;
        font-weight: 800;
      
      }
      

}
